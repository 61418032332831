import React, { useState, useEffect } from "react";
import {
  Table,
  Th,
  Thead,
  Tbody,
  Td,
  Tr,
  Title,
  TopContainer,
} from "../Product/ProductElement";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { get, put,post, remove } from "../../helpers/apiConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Button, Drawer, Form, Input, Select, DatePicker, Modal } from "antd";
import { validateMessages } from "../../helpers/validation";
import moment from "moment";

const { Option } = Select;

function Customer() {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingCustomer, setEditingCustomer] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  let token = localStorage.getItem("token");

  const getAllCustomers = async () => {
    try {
      setIsLoading(true);
      const response = await get(`/admin/users?token=${token}`);

      console.log(response?.data);
      if (response.data.success) {
        setCustomers(response.data.users);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCustomers();
  }, []);

  const handleEdit = (customer) => {
    setEditingCustomer(customer);
    form.setFieldsValue({ ...customer, dob: customer.dob ? moment(new Date(customer.dob)) : null, });
    setDrawerVisible(true);
  };


  const confirmDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this customer?",
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => handleDelete(id),
    });
  };
  

  const handleDelete = async (id) => {
    try {
      const res = await remove(`/admin/customer/${id}?token=${token}`);
      if (res.data.success) {
        setCustomers(customers.filter((customer) => customer._id !== id));
      }
    } catch (error) {
      console.error("There was an error deleting the customer!", error);
    }
  };

  const handleAddCustomer = () => {
    setEditingCustomer(null);
    form.resetFields();
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const onFinish = async (values) => {
    try {
      values.dob = values.dob.format("YYYY-MM-DD");
      if (editingCustomer) {

       
        await put(`/admin/customer/${editingCustomer._id}?token=${token}`, values);
        setCustomers(
          customers?.map((customer) =>
            customer._id === editingCustomer._id ? { ...customer, ...values } : customer
          )
        );
      } else {
        const response = await post(`/admin/customer/new?token=${token}`, values);
        setCustomers([...customers, response.data.customer]);
      }

      setDrawerVisible(false);
    } catch (error) {
      console.error("There was an error saving the customer!", error);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filteredCustomers = customers.filter(
      (customer) =>
        customer.first_name.toLowerCase().includes(query) ||
        customer.last_name.toLowerCase().includes(query) ||
        customer.email.toLowerCase().includes(query)
    );
    setCustomers(filteredCustomers);
  };



  return (
    <div style={{ backgroundColor: "#FFF", width: "100vw" }}>
      <TopContainer>
        <Title>Customers</Title>
        <Input
          placeholder="Search customers"
          value={searchQuery}
          onChange={handleSearch}
          style={{ marginRight: 10, width: 200 }}
        />
        {/* <Button
          type="default"
          onClick={handleAddCustomer}
          style={{ margin: 10 }}
        >
          Add Customer
        </Button> */}
      </TopContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Id</Th>
            <Th>First Name</Th>
            <Th>Last Name</Th>
            <Th>Date of Birth</Th>
            <Th>Phone Number</Th>
            <Th>Email</Th>
            <Th>Gender</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading && <LoadingSpinner />}
          {customers?.map((customer) => (
            <Tr key={customer._id}>
              <Td>{customer._id}</Td>
              <Td>{customer.first_name}</Td>
              <Td>{customer.last_name}</Td>
              <Td>{customer.dob}</Td>
              <Td>{customer.phone_number}</Td>
              <Td>{customer.email}</Td>
              <Td>{customer.gender}</Td>
              <Td>
                <EditOutlined
                  onClick={() => handleEdit(customer)}
                  style={{ marginRight: 8, cursor: "pointer" }}
                />
                <DeleteOutlined
                  onClick={() => confirmDelete(customer._id)}
                  style={{ cursor: "pointer" }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Drawer
        title={editingCustomer ? "Edit Customer" : "Add Customer"}
        width={720}
        onClose={onClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form} validateMessages={validateMessages}>
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[
              { required: true, message: "Please enter the customer's first name" },
              { max: 30, message: "First name cannot exceed 30 characters" },
              { min: 4, message: "First name should have more than 4 characters" },
            ]}
          >
            <Input placeholder="Please enter the customer's first name" />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[
              { required: true, message: "Please enter the customer's last name" },
              { max: 30, message: "Last name cannot exceed 30 characters" },
              { min: 1, message: "Last name should have more than 1 character" },
            ]}
          >
            <Input placeholder="Please enter the customer's last name" />
          </Form.Item>
          <Form.Item
            name="dob"
            label="Date of Birth"
            rules={[{ required: true, message: "Please enter the date of birth" }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="phone_number"
            label="Phone Number"
            rules={[
              { max: 30, message: "Phone number cannot exceed 30 characters" },
              { min: 10, message: "Phone number should have 10 characters" },
            ]}
          >
            <Input placeholder="Please enter the phone number" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please enter the customer's email" },
              { type: 'email', message: "Please enter a valid email" },
            ]}
          >
            <Input placeholder="Please enter the customer's email" />
          </Form.Item>
          <Form.Item
            name="gender"
            label="Gender"
            rules={[{ required: true, message: "Please select the gender" }]}
          >
            <Select placeholder="Please select the gender">
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingCustomer ? "Update" : "Add"} Customer
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default Customer;
