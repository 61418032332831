import React, { useState } from "react";
import {
  PhnText,
  ProfileDiv,
  Div,
  LogoAd,
  SubDiv,
  LogoContainer,
} from "./sidepanelElement";
import {
  DashboardOutlined,
  ShoppingCartOutlined,
  TagOutlined,
  UserOutlined,
  ShopOutlined,
  AppstoreOutlined,
  DownOutlined,
  UpOutlined,
  MenuOutlined,
  UnorderedListOutlined,
  CreditCardOutlined,
  DollarCircleOutlined,
  LayoutOutlined,
  GiftOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import LogoSrc from "../../images/logo_rb.png";

const Sidepanel = () => {
  const navigate = useNavigate();
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const loggedUser = JSON.parse(localStorage.getItem("LOGGED_USER"));
  const role = loggedUser?.role;

  const toggleProducts = () => {
    setIsProductsOpen(!isProductsOpen);
  };

  return (
    <ProfileDiv>
      <LogoContainer>
        <LogoAd src={LogoSrc} />
      </LogoContainer>
      <Div>
        <SubDiv onClick={() => navigate(`/?role=${role}`)}>
          <DashboardOutlined />
          <PhnText>Dashboard</PhnText>
        </SubDiv>
        {/* <SubDiv onClick={toggleProducts}>
          <ShoppingCartOutlined />
          <PhnText>Products</PhnText>
          {isProductsOpen ? <UpOutlined /> : <DownOutlined />}
        </SubDiv> */}
        {/* {isProductsOpen && (
          <Div style={{ paddingLeft: "20px" }}> */}
        {/* {role === "admin" && (
          <SubDiv onClick={() => navigate("/own-products")}>
            <UnorderedListOutlined />
            <PhnText>Products</PhnText>
          </SubDiv>
        )} */}

        <SubDiv onClick={() => navigate("/new-orders")}>
          <UnorderedListOutlined />
          <PhnText>New Orders</PhnText>
        </SubDiv>

        {/* {role === "admin" && (
          <SubDiv onClick={() => navigate("/own-products-orders")}>
            <UnorderedListOutlined />
            <PhnText>Orders</PhnText>
          </SubDiv>
        )} */}
        <SubDiv onClick={() => navigate("/products")}>
          <UnorderedListOutlined />
          <PhnText>{role == "admin" ? "All" : ""}Products</PhnText>
        </SubDiv>
        <SubDiv onClick={() => navigate("/orders")}>
          <ShoppingCartOutlined />
          <PhnText>{role == "admin" ? "All" : ""}Orders</PhnText>
        </SubDiv>
        {role === "admin" && (
          <SubDiv onClick={() => navigate("/category")}>
            <AppstoreOutlined />
            <PhnText>Categories</PhnText>
          </SubDiv>
        )}
        {role === "admin" && (
          <SubDiv onClick={() => navigate("/sub-category")}>
            <AppstoreOutlined />
            <PhnText>Sub Categories</PhnText>
          </SubDiv>
        )}
        {role !== "admin" && (
          <SubDiv onClick={() => navigate("/offers")}>
            <GiftOutlined />
            <PhnText>Hot Offers</PhnText>
          </SubDiv>
        )}
        {role === "admin" && (
          <SubDiv onClick={() => navigate("/customers")}>
            <SolutionOutlined />
            <PhnText>Customers</PhnText>
          </SubDiv>
        )}
        {role === "admin" && (
          <SubDiv onClick={() => navigate("/seller")}>
            <ShopOutlined />
            <PhnText>Sellers</PhnText>
          </SubDiv>
        )}
        {role === "admin" && (
          <SubDiv onClick={() => navigate("/commission")}>
            <DollarCircleOutlined />
            <PhnText>Commission</PhnText>
          </SubDiv>
        )}
        {role === "admin" && (
          <SubDiv onClick={() => navigate("/banners")}>
            <LayoutOutlined />
            <PhnText>Banners</PhnText>
          </SubDiv>
        )}

        {role !== "admin" && (
          <SubDiv onClick={() => navigate("/vendor-payment-details")}>
            <CreditCardOutlined />
            <PhnText>Orders & Summary</PhnText>
          </SubDiv>
        )}
        {role === "admin" && (
          <SubDiv onClick={() => navigate("/payment-summary")}>
            <CreditCardOutlined />
            <PhnText>Payout</PhnText>
          </SubDiv>
        )}

        <SubDiv>
          <p
            style={{
              textAlign: "center",
              fontSize: "10px",
              flex: 1,
              marginTop: "10px",
              fontWeight: "bold",
            }}
          >
            Version no. 0.1.96 {process.env.APPNAME}
          </p>
        </SubDiv>
      </Div>
    </ProfileDiv>
  );
};

export default Sidepanel;
