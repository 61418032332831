import React, { useState, useEffect } from "react";
import {
  Table,
  Th,
  Thead,
  Tbody,
  Td,
  Tr,
  Title,
  TopContainer,
} from "../Product/ProductElement";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { get, post, put, remove, serverName } from "../../helpers/apiConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Button, Drawer, Form, Input, Modal, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  removeImageFromAzure,
  uploadImageToAzure,
} from "../../utils/uploadImageToAzure";

const { TextArea } = Input;

function Category() {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  let token = localStorage.getItem("token");

  const [isUpLoading, setIsUpLoading] = useState(false);

  // const IMAGE_URL = "https://ddotstaticdev.blob.core.windows.net/images/";
  const IMAGE_URL = process.env.REACT_APP_BLOB_IMAGE_BASE_URL || "";

  const handleDivClick = () => {
    navigate("/categories");
  };

  const getAllCategories = async () => {
    try {
      setIsLoading(true);
      const response = await get(`/categories?token=${token}`);
      if (response.data.success) {
        setCategories(response.data.categories);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const handleEdit = (category) => {
    setEditingCategory(category);
    form.setFieldsValue(category);
    setDrawerVisible(true);
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this category?",
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => handleDelete(id),
    });
  };

  const handleDelete = async (id) => {
    try {
      const res = await remove(`/admin/category/${id}`);
      if (res.data.success) {
        setCategories(categories.filter((category) => category._id !== id));
      }
    } catch (error) {
      console.error("There was an error deleting the category!", error);
    }
  };

  const handleAddCategory = () => {
    setEditingCategory(null);
    form.resetFields();
    setDrawerVisible(true);
  };

  const onClose = () => {
    form.resetFields();
    setDrawerVisible(false);
  };

  const onFinish = async (values) => {
    setIsUpLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      if (values.image?.fileList) {
        if (editingCategory) {
          await removeImageFromAzure(
            IMAGE_URL + editingCategory?.image,
            "images/category-images"
          );
        }

        const url = await uploadImageToAzure(
          values.image.fileList[0].originFileObj,
          "images/category-images"
        );
        const blobName = url.split(`/images/`)[1];
        console.log("blobName, ", blobName);
        formData.append("image", blobName);
      }

      let response;
      if (editingCategory) {
        response = await put(
          `/admin/category/${editingCategory._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        response = await post(`/admin/category/new?token=${token}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      if (response.data.success) {
        message.success(
          `Category ${editingCategory ? "updated" : "added"} successfully`
        );
        onClose();
        getAllCategories();
      } else {
        message.error(
          `Failed to ${editingCategory ? "update" : "add"} category`
        );
      }

      setIsUpLoading(false);
    } catch (error) {
      setIsUpLoading(false);
      console.error(
        `Error ${editingCategory ? "updating" : "adding"} category:`,
        error
      );
      message.error(`Failed to ${editingCategory ? "update" : "add"} category`);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = categories.filter(
      (category) =>
        category.name.toLowerCase().includes(query) ||
        category.description.toLowerCase().includes(query)
    );
    setCategories(filtered);
  };

  return (
    <div style={{ backgroundColor: "#FFF", width: "100vw" }}>
      <TopContainer>
        <Title>Categories</Title>

        <Input
          placeholder="Search categories"
          value={searchQuery}
          onChange={handleSearch}
          style={{ marginRight: 10, width: 200 }}
        />

        <Button
          type="default"
          onClick={handleAddCategory}
          style={{ margin: 10 }}
        >
          Add Category
        </Button>
      </TopContainer>
      <Table>
        <Thead>
          <tr>
            <Th>Id</Th>
            <Th>Name</Th>
            <Th>Description</Th>
            <Th>Image</Th>
            <Th>Action</Th>
          </tr>
        </Thead>
        <Tbody>
          {isLoading && <LoadingSpinner />}
          {categories.map((category) => (
            <tr key={category?._id}>
              <Td>{category?._id}</Td>
              <Td>{category?.name}</Td>
              <Td>{category?.description}</Td>
              {console.log(IMAGE_URL + category?.image)}
              <Td>
                {category?.image && (
                  <img
                    src={IMAGE_URL + category?.image}
                    alt={category?.name}
                    width="50"
                  />
                )}
              </Td>
              <Td>
                <EditOutlined
                  onClick={() => handleEdit(category)}
                  style={{ marginRight: 8, cursor: "pointer" }}
                />
                <DeleteOutlined
                  onClick={() => confirmDelete(category._id)}
                  style={{ cursor: "pointer" }}
                />
              </Td>
            </tr>
          ))}
        </Tbody>
      </Table>

      <Drawer
        title={editingCategory ? "Edit Category" : "Add Category"}
        width={720}
        onClose={onClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              { required: true, message: "Please enter the category name" },
            ]}
          >
            <Input placeholder="Please enter the category name" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: "Please enter the category description",
              },
            ]}
          >
            <TextArea
              rows={4}
              placeholder="Please enter the category description"
            />
          </Form.Item>
          <Form.Item
            name="image"
            label="Image"
            rules={[
              {
                required: true,
                message: "Please select an image for the category",
              },
            ]}
            valuePropName="file"
            getValueFromEvent={(e) => (e.file ? e : null)}
          >
            <Upload
              name="image"
              listType="picture"
              maxCount={1}
              beforeUpload={() => false} // Prevent automatic upload
            >
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>
          <div style={{ height: "20px" }} />
          <Form.Item>
            <Button disabled={isUpLoading} type="primary" htmlType="submit">
              {isUpLoading
                ? "Please wait..."
                : editingCategory
                ? "Update Category"
                : "Add Category"}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default Category;
