import React, { useState } from "react";
import {
  Main,
  Text,
  SubText,
  Input,
  AccText,
  Signup,
  AccDiv,
  InputDiv,
  Row,
  Column,
  Label,
} from "./SignupElements";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { post } from "../../helpers/apiConfig";
import { uploadImageToAzure } from "../../utils/uploadImageToAzure";

const SignUp = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [dob, setDob] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [registrationNo, setRegistrationNo] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  // const IMAGE_URL = process.env.REACT_APP_BLOB_IMAGE_BASE_URL || "";
  const IMAGE_URL = "https://ddotstaticdev.blob.core.windows.net/images/";

  const [isLoading, setIsLoading] = useState(false);

  const handleAvatarChange = (event) => {
    setAvatar(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);
    if (password != cPassword) {
      notification.error({
        message: "Passwords do not match",
        description: "Passwords do not match",
        placement: "topRight",
      });
      return;
    }
    event.preventDefault();
    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("phone_number", phoneNumber);
    formData.append("role", "vendor");
    formData.append("companyName", companyName);
    formData.append("businessName", businessName);
    formData.append("registrationNumber", registrationNo);
    formData.append("registrationDate", registrationDate);

    try {
      if (avatar) {
        const url = await uploadImageToAzure(avatar, "images/profile-images");
        const blobName = url.split(`/images/`)[1];
        formData.append("avatar", blobName);
      }

      const response = await post("/register", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response?.data?.success) {
        notification.success({
          message: "Registration Successful",
          description:
            "You have successfully registered as a vendor. Admin approval will be sent to your email.",
        });
        navigate("/signin");

        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setDob("");
        setPhoneNumber("");
        setCompanyName("");
        setBusinessName("");
        setRegistrationNo("");
        setRegistrationDate("");
        setAvatar(null);
        setError("");
      } else {
        notification.error({
          message: "Registration Failed",
          description: response?.data?.message || "An error occurred.",
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      notification.error({
        message: error?.response?.data?.message,
        // description: "Passwords do not match",
        placement: "topRight",
      });
      setError("Registration failed. Please try again.");
    }
  };

  return (
    <Main>
      <Text>Sign Up</Text>
      <SubText>Join us as a vendor and start selling your products!</SubText>
      <Text>{error && <p>{error}</p>}</Text>

      <Row>
        <Column>
          <InputDiv>
            <Label>First Name</Label>
            <Input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </InputDiv>
        </Column>
        <Column>
          <InputDiv>
            <Label>Last Name</Label>
            <Input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </InputDiv>
        </Column>
      </Row>

      <Row>
        {/* <Column>
          <InputDiv>
            <Label>Date of Birth</Label>
            <Input
              type="date"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
          </InputDiv>
        </Column> */}
        <Column>
          <InputDiv>
            <Label>Phone Number</Label>
            <Input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </InputDiv>
        </Column>
        <Column>
          <InputDiv>
            <Label>Email</Label>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputDiv>
        </Column>
      </Row>

      <Row>
        <Column>
          <InputDiv>
            <Label>Company Name</Label>
            <Input
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </InputDiv>
        </Column>
        <Column>
          <InputDiv>
            <Label>Business Name</Label>
            <Input
              type="text"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
            />
          </InputDiv>
        </Column>
        <Column>
          <InputDiv>
            <Label>Registration Number</Label>
            <Input
              type="text"
              value={registrationNo}
              onChange={(e) => setRegistrationNo(e.target.value)}
            />
          </InputDiv>
        </Column>
        <Column>
          <InputDiv>
            <Label>Registration Date</Label>
            <Input
              type="date"
              value={registrationDate}
              onChange={(e) => setRegistrationDate(e.target.value)}
            />
          </InputDiv>
        </Column>
      </Row>
      <Row>
        <Column>
          <InputDiv>
            <Label>Password</Label>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputDiv>
        </Column>
        <Column>
          <InputDiv>
            <Label>Confirm Password</Label>
            <Input
              type="password"
              value={cPassword}
              onChange={(e) => setCPassword(e.target.value)}
            />
          </InputDiv>
        </Column>
      </Row>

      <Row>
        <Column>
          <InputDiv>
            <Label>Avatar</Label>
            <Input type="file" onChange={handleAvatarChange} />
          </InputDiv>
        </Column>
      </Row>

      <Button isLoading={isLoading} title="Register" onClick={handleSubmit} />

      <AccDiv>
        <AccText>Already have an account?</AccText>
        <Signup onClick={() => navigate("/signin")}>Login</Signup>
      </AccDiv>
    </Main>
  );
};

export default SignUp;
