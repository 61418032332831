import React, { useState } from "react";
import { post } from "../../helpers/apiConfig";
import { message } from "antd";

const DeleteAccountPage = () => {
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const data = { email, reason };

      const response = await post("/request/delete-account", data);
    
      if (response.data?.success) {
        message.success(response.data?.message);
        setSubmitted(true);
      } else {
        message.error(response.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);

      console.error("Error submitting request:", e);
    }
  };

  if (submitted) {
    return (
      <div style={styles.container}>
        <div style={styles.card}>
          <h2>Request Submitted</h2>
          <p>
            Thank you for submitting your account deletion request. You will
            receive an email shortly for confirmation. Follow the instructions
            in the email to complete the process.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h1 style={styles.appName}>DdotCart</h1>
        <h2 style={styles.title}>Request Account Deletion</h2>
        <p style={styles.description}>
          We're sorry to see you go! To request the deletion of your account,
          please provide your email and the reason for the deletion. After
          submitting the form, you'll receive an email to confirm your request.
          If you don't confirm, your account will remain active.
        </p>
        <form onSubmit={handleSubmit} style={styles.form}>
          <label htmlFor="email" style={styles.label}>
            Email:
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            style={styles.input}
            required
          />

          <label htmlFor="reason" style={styles.label}>
            Reason for Deletion:
          </label>
          <textarea
            id="reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Enter your reason for account deletion"
            rows="4"
            style={styles.textarea}
            required
          ></textarea>

          <button disabled={isLoading} type="submit" style={styles.button}>
            {isLoading ? "Please wait..." : "Submit Request"}
          </button>
        </form>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    padding: "20px",
    backgroundColor: "#f9f9f9",
  },
  card: {
    maxWidth: "800px",
    width: "100%",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    padding: "30px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  appName: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#9D2233",
    marginBottom: "10px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "10px",
  },
  description: {
    fontSize: "16px",
    color: "#555",
    marginBottom: "20px",
    lineHeight: "1.6",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  label: {
    textAlign: "left",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "5px",
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    width: "100%",
    boxSizing: "border-box",
  },
  textarea: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    width: "100%",
    boxSizing: "border-box",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#ffffff",
    backgroundColor: "#9D2233",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default DeleteAccountPage;
