import {
  BlobServiceClient,
  StorageSharedKeyCredential,
} from "@azure/storage-blob";

const sasToken = process.env.REACT_APP_BLOB_SAS_TOKEN ?? "";
const accountName = process.env.REACT_APP_BLOB_ACCOUNT_NAME ?? "";

export const uploadImageToAzure = async (file, containerName = "images") => {

  try {
    const blobSasUrl = `https://ddotstaticdev.blob.core.windows.net?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2027-02-11T12:57:33Z&st=2024-11-26T04:57:33Z&spr=https&sig=w2dzMj1PuqCq9U8HeVPsyKeaMoyZH4nze%2BV0mdTc6Bk%3D`;
    // const blobSasUrl = `https://${accountName}.blob.core.windows.net?${sasToken}`;
    const blobServiceClient = new BlobServiceClient(blobSasUrl);
    const containerClient = blobServiceClient.getContainerClient(containerName);

    const blobName = `${Date.now()}-${file.name}`;
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    // Set Content-Type explicitly
    const options = {
      blobHTTPHeaders: {
        blobContentType: file.type, // Set the Content-Type (e.g., "image/jpeg")
      },
    };

    // Upload file
    const uploadResponse = await blockBlobClient.uploadData(file, options);
    console.log("Upload successful:", uploadResponse);

    // Return URL of uploaded file
    const uploadedBlobUrl = `${
      blobSasUrl.split("?")[0]
    }/${containerName}/${blobName}`;
    console.log("File URL:", uploadedBlobUrl);

    return uploadedBlobUrl;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

// Remove Image Function
export const removeImageFromAzure = async (blobUrl, containerName = "images") => {

  console.log("deleteImageFromAzure", blobUrl, containerName);
  
  try {
    const blobSasUrl = `https://ddotstaticdev.blob.core.windows.net?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2027-02-11T12:57:33Z&st=2024-11-26T04:57:33Z&spr=https&sig=w2dzMj1PuqCq9U8HeVPsyKeaMoyZH4nze%2BV0mdTc6Bk%3D`;

    const blobServiceClient = new BlobServiceClient(blobSasUrl);
    const containerClient = blobServiceClient.getContainerClient(containerName);

    // Extract the blob name from the URL
    const blobName = blobUrl.split(`/${containerName}/`)[1];
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    // Delete blob
    const deleteResponse = await blockBlobClient.delete();
    console.log("Blob deleted successfully:", deleteResponse);

    return true;
  } catch (error) {
    console.error("Error deleting image:", error);
    throw error;
  }
};
