import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  Th,
  Thead,
  Tbody,
  Td,
  Tr,
  Title,
  TopContainer,
} from "../Product/ProductElement";
import { get } from "../../helpers/apiConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import { notification } from "antd";

function VendorPaymentDetails() {
  const [summary, setSummary] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  let token = localStorage.getItem("token");

  useEffect(() => {
    fetchVendorPaymentSummary();
  }, []);

  const fetchVendorPaymentSummary = async () => {
    try {
      setIsLoading(true);
      const response = await get(
        `/orders/payment-summary/vendor/?token=${token}`
      );
      if (response.data.success) {
        console.log(response?.data);
        setSummary(response.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error.response);
      notification.error({
        message: "Error",
        description: "There was an error fetching the vendor payment summary.",
        placement: "topRight",
      });
    }
  };

  return (
    <div style={{ backgroundColor: "#FFF", width: "100vw" }}>
      <TopContainer>
        <Title>Order Summary</Title>
      </TopContainer>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div
            style={{
              display: "grid",
              width: "75%",
              gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
              gap: "15px",
              padding: "10px",
              marginLeft: "15px",
            }}
          >
            {[
              { label: "Total Orders", value: summary.totalOrders },
              {
                label: "Total Amount",
                value: summary.totalAmount?.toString().slice(0, 6),
              },
              {
                label: "Coupon Deduction",
                value: summary.totalDiscount?.toString().slice(0, 6),
              },
              {
                label: "Admin Fee",
                value: summary.adminFee?.toString().slice(0, 6),
              },
              {
                label: "Net Amount",
                value: summary.netAmount?.toString().slice(0, 6),
              },
              {
                label: "Paid Amount",
                value: summary.paidAmount?.toString().slice(0, 6),
              },
              {
                label: "Remaining Amount",
                value: summary.remainingAmount?.toString().slice(0, 6),
              },
            ].map((item, index) => (
              <p
                key={index}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "10px",
                  backgroundColor: "#f9f9f9",
                  textAlign: "center",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <strong>{item.label}:</strong> {item.value}
              </p>
            ))}
          </div>

          <h4>Items List:</h4>
          <Table>
            <Thead>
              <Tr>
                <Th>Order ID</Th>
                <Th>Title</Th>
                <Th>Quantity</Th>
                <Th>Order Price</Th>
                <Th>Admin Commission</Th>
                <Th>Coupon Deduction</Th>
                <Th>Final Amount</Th>
                <Th>Tracking Status</Th>
                <Th>Payment Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {summary?.itemsList?.map((item) => (
                <Tr key={item._id}>
                  <Td>{item._id}</Td>
                  <Td style={{ whiteSpace: "nowrap" }}>
                    {item.product[0]?.title}
                  </Td>
                  <Td>{item.quantity}</Td>
                  <Td>
                    {(item.price * item.quantity)?.toString().slice(0, 6)}
                  </Td>
                  <Td>{item.adminFee * item?.quantity}</Td>
                  <Td>{item.discount * item.quantity}</Td>

                  <Td>
                    {(item?.finalAmount * item.quantity)
                      ?.toString()
                      .slice(0, 6)}
                  </Td>
                  <Td>{item.trackingStatus}</Td>
                  <Td>{item.paymentStatus}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      )}
    </div>
  );
}

export default VendorPaymentDetails;
