import React, { useState, useEffect } from "react";
import {
  Table,
  Th,
  Thead,
  Tbody,
  Td,
  Title,
  TopContainer,
} from "../Product/ProductElement";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { get, post, put, remove, serverName } from "../../helpers/apiConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Button, Drawer, Form, Input, message } from "antd";

const { TextArea } = Input;

function Commission() {
  const [commissions, setCommissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingCommission, setEditingCommission] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const token = localStorage.getItem("token");

  const getAllCommissions = async () => {
    try {
      setIsLoading(true);
      const response = await get(`/admin/payment/commission?token=${token}`);
      if (response.data.success) {
        setCommissions(response.data.commissionAmounts);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error("Error fetching commission amounts:", e);
    }
  };

  useEffect(() => {
    getAllCommissions();
  }, []);

  const handleEdit = (commission) => {
    setEditingCommission(commission);
    form.setFieldsValue(commission);
    setDrawerVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      const res = await remove(`/admin/payment/commission/${id}?token=${token}`);
      if (res.data.success) {
        setCommissions(commissions.filter((commission) => commission._id !== id));
      }
    } catch (error) {
      console.error("There was an error deleting the commission amount!", error);
    }
  };

  const handleAddCommission = () => {
    setEditingCommission(null);
    form.resetFields();
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const onFinish = async (values) => {
    console.log(values);
    try {
      let response;
      if (editingCommission) {
        response = await put(`/admin/payment/commission/${editingCommission._id}?token=${token}`, values);
      } else {
        response = await post(`/admin/payment/commission/new?token=${token}`, values);
      }

      if (response.data.success) {
        message.success(`Commission ${editingCommission ? 'updated' : 'added'} successfully`);
        onClose();
        getAllCommissions();
      } else {
        message.error(`Failed to ${editingCommission ? 'update' : 'add'} commission`);
      }
    } catch (error) {
      console.error(`Error ${editingCommission ? 'updating' : 'adding'} commission:`, error);
      message.error(`Failed to ${editingCommission ? 'update' : 'add'} commission`);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = commissions.filter(
      (commission) =>
        commission.title.toLowerCase().includes(query) ||
        commission.commission_amount_percentage.toString().includes(query) ||
        commission.max_comcommission_amount.toString().includes(query)
    );
    setCommissions(filtered);
  };

  return (
    <div style={{ backgroundColor: "#FFF", width: "100vw" }}>
      <TopContainer>
        <Title>Commission Settings</Title>

        <Input
          placeholder="Search commissions"
          value={searchQuery}
          onChange={handleSearch}
          style={{ marginRight: 10, width: 200 }}
        />

        <Button
          type="default"
          onClick={handleAddCommission}
          style={{ margin: 10 }}
        >
          Add Commission
        </Button>
      </TopContainer>
      <Table>
        <Thead>
          <tr>
            <Th>Id</Th>
            <Th>Title</Th>
            <Th>Commission Percentage</Th>
            <Th>Max Commission Amount</Th>
            <Th>Action</Th>
          </tr>
        </Thead>
        <Tbody>
          {isLoading && <LoadingSpinner />}
          {commissions.map((commission) => (
            <tr key={commission._id}>
              <Td>{commission._id}</Td>
              <Td>{commission.title}</Td>
              <Td>{commission.commission_amount_percentage}</Td>
              <Td>{commission.max_comcommission_amount}</Td>
              <Td>
                <EditOutlined
                  onClick={() => handleEdit(commission)}
                  style={{ marginRight: 8, cursor: "pointer" }}
                />
                <DeleteOutlined
                  onClick={() => handleDelete(commission._id)}
                  style={{ cursor: "pointer" }}
                />
              </Td>
            </tr>
          ))}
        </Tbody>
      </Table>

      <Drawer
        title={editingCommission ? "Edit Commission" : "Add Commission"}
        width={720}
        onClose={onClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Please enter the commission title" }]}
          >
            <Input placeholder="Please enter the commission title" />
          </Form.Item>
          <Form.Item
            name="commission_amount_percentage"
            label="Commission Percentage"
            rules={[{ required: true, message: "Please enter the commission percentage" }]}
          >
            <Input placeholder="Please enter the commission percentage" type="number" />
          </Form.Item>
          <Form.Item
            name="max_comcommission_amount"
            label="Max Commission Amount"
            rules={[{ required: true, message: "Please enter the maximum commission amount" }]}
          >
            <Input placeholder="Please enter the maximum commission amount" type="number" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingCommission ? "Update" : "Add"} Commission
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default Commission;
