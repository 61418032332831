import React, { useEffect } from "react";
import {
  Nav,
  Arr,
  Spacer,
  NavBtn,
  Drop,
  PhnText,
  Logo,
  NavBtnLink,
  NavMenu,
  Input,
  LogoAd,
  NavbarTitle,
} from "./navElements";
import LogoSrc from "../../images/Vogue.png";
import Profile from "../../images/Ellise.jpeg";
import Arrow from "../../images/Vector.png";
import {
  LoginOutlined,
  LogoutOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { avatarImageURL, imageURL } from "../../helpers/apiConfig";

const NavBar = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(false);
  // const IMAGE_URL = process.env.REACT_APP_BLOB_IMAGE_BASE_URL || "";
  const IMAGE_URL = "https://ddotstaticdev.blob.core.windows.net/images/";

  const handleOpen = () => {
    setOpen(!open);
  };
  const handleMenuOne = () => {
    setOpen(false);
    setValue("Customer");
  };

  const handleMenuTwo = () => {
    setOpen(false);
    setValue("Photograper");
  };
  const navigation = useNavigate();

  const handleLogout = () => {
    let user = localStorage.removeItem("LOGGED_USER");

    navigation("/Signin");
  };
  const handle1 = () => {
    navigation("/Profile");
  };

  console.log(avatarImageURL + data?.avatar);

  return (
    <>
      <Nav>
        <NavbarTitle></NavbarTitle>

        <NavBtnLink>
          <NavBtnLink>
            <NavMenu>{data?.first_name}</NavMenu>

            <NavBtn onClick={handleOpen}>
              <Logo
                src={data?.avatar ? IMAGE_URL + data?.avatar : Profile}
              />
            </NavBtn>
            <Spacer />
          </NavBtnLink>
        </NavBtnLink>
      </Nav>
      {open ? (
        <Drop>
          <PhnText style={{ zIndex: 900000 }} onClick={handleLogout}>
            <LogoutOutlined /> Sign Out
          </PhnText>
        </Drop>
      ) : null}
    </>
  );
};

export default NavBar;
