import React, { useState, useEffect } from "react";
import {
  Table,
  Th,
  Thead,
  Tbody,
  Td,
  Tr,
  Title,
  TopContainer,
} from "../Product/ProductElement";
import { useNavigate } from "react-router-dom";
import { get, put } from "../../helpers/apiConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  Button,
  Drawer,
  Form,
  Select,
  Input,
  Modal,
  List,
  notification,
} from "antd";
import { validateMessages } from "../../helpers/validation";
import jsPDF from "jspdf";

const { Option } = Select;

function Order({ type = "", isAdmin = false }) {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [customerModalVisible, setCustomerModalVisible] = useState(false);
  const [editingOrder, setEditingOrder] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [itemForms, setItemForms] = useState(new Map());
  let token = localStorage.getItem("token");

  useEffect(() => {
    getAllOrders();
  }, [type]);

  const getAllOrders = async () => {
    try {
      setIsLoading(true);
      const url = `/orders/admin?token=${token}`;
      const adminUrl = `/orders/admin?token=${token}&type=${type}`;
      let response;
      if (type === "admin") {
        response = await get(adminUrl);
      } else {
        response = await get(url);
      }

      if (response.data.success) {
        setOrders(response.data.orders);
      }
      setIsLoading(false);
    } catch (e) {
      setOrders([]);
      console.log(e.response?.data);
      setIsLoading(false);
    }
  };

  const handleEdit = (order) => {
    setEditingOrder(order);
    form.setFieldsValue(order);
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const onFinish = async (values) => {
    handleStatusUpdate(editingOrder?._id, values?.status);
    setEditingOrder(null);
    onClose();
  };

  const handleStatusUpdate = async (id, status) => {
    try {
      await put(`/order/status/${id}?token=${token}`, { status });

      const updatedOrders = orders.map((order) =>
        order._id === id ? { ...order, status: status } : order
      );
      setOrders(updatedOrders);

      notification.success({
        message: "Success",
        description: `Order status updated to ${status}`,
        placement: "topRight",
      });
    } catch (error) {
      console.error("There was an error updating the order status!", error);
      notification.error({
        message: "Error",
        description: "There was an error updating the order status.",
        placement: "topRight",
      });
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filteredOrders = orders.filter(
      (order) =>
        order._id.toLowerCase().includes(query) ||
        order.user?.first_name?.toLowerCase().includes(query)
    );
    setOrders(filteredOrders);
  };

  const showItems = (items) => {
    setCurrentItems(items);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleItemStatusUpdate = async (itemId, trackingStatus) => {
    try {
      const orderId = editingOrder._id;
      await put(`/order/product-tracking-status/${orderId}?token=${token}`, {
        productId: itemId,
        trackingStatus,
      });

      const updatedOrders = orders.map((order) =>
        order._id === orderId
          ? {
              ...order,
              items: order.items.map((item) =>
                item._id === itemId
                  ? { ...item, trackingStatus: trackingStatus }
                  : item
              ),
            }
          : order
      );
      setOrders(updatedOrders);

      notification.success({
        message: "Success",
        description: `Item tracking status updated to ${trackingStatus}`,
        placement: "topRight",
      });
    } catch (error) {
      console.error(
        "There was an error updating the item tracking status!",
        error
      );
      notification.error({
        message: "Error",
        description: "There was an error updating the item tracking status.",
        placement: "topRight",
      });
    }
  };

  const handleCustomerDetails = (user) => {
    console.log(user);

    setSelectedCustomer(user);
    setCustomerModalVisible(true);
  };

  const handleCustomerModalClose = () => {
    setCustomerModalVisible(false);
  };

  const handlePrintPDF = () => {
    if (!selectedCustomer) return;

    const doc = new jsPDF();
    console.log();
    

    // Add Customer Details to PDF
    doc.setFontSize(16);
    doc.text("Customer Details", 10, 10);
    doc.setFontSize(12);
    doc.text(
      `Full Name: ${selectedCustomer.first_name} ${selectedCustomer.last_name}`,
      10,
      20
    );
    doc.text(`Phone: ${selectedCustomer.address?.phone_no}`, 10, 40);

    const address = selectedCustomer.address
      ? `${selectedCustomer.address.address_name}, ${
          selectedCustomer.address.house_number
        }, ${selectedCustomer.address.street_name}, ${
          selectedCustomer.address.locality_name || ""
        }, ${selectedCustomer.address.town || ""}, ${
          selectedCustomer.address.postcode
        }`
      : "No address available";

    doc.text(`Address: ${address}`, 10, 50);

    doc.save("Customer_Details.pdf");
  };

  return (
    <div style={{ backgroundColor: "#FFF", width: "100vw" }}>
      <TopContainer>
        <Title>{isAdmin ? "All " : ""}Orders</Title>
        <Input
          placeholder="Search orders"
          value={searchQuery}
          onChange={handleSearch}
          style={{ width: 200 }}
        />
      </TopContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Order ID</Th>
            <Th>Customer Name</Th>
            <Th>Cart Amount</Th>
            <Th>Discount</Th>
            <Th>Net Amount</Th>
            <Th>Placed Date</Th>
            <Th>Expected Date</Th>
            <Th>Items</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading && <LoadingSpinner />}
          {orders.map((order) => {
            // console.log(order?.shippingAddress);

            return (
              <Tr key={order._id}>
                <Td>{order._id}</Td>
                <Td>
                  <Button
                    type="link"
                    onClick={() =>
                      handleCustomerDetails({
                        ...order.user,
                        address: order?.shippingAddress,
                      })
                    }
                  >
                    {order.user?.first_name}
                  </Button>
                </Td>
                <Td>{order.totalAmount?.toFixed(2)}</Td>
                <Td>{order?.totalDiscount?.toFixed(2)}</Td>
                <Td>{(order.totalAmount - order.totalDiscount)?.toFixed(2)}</Td>
                <Td>{order.createdAt?.slice(0, 10)}</Td>
                <Td>{order?.items[0]?.expectedDeliveryDate?.slice(0, 10)}</Td>
                <Td>
                  <Button
                    onClick={() => {
                      setEditingOrder(order);
                      showItems(order.items);
                    }}
                  >
                    View Items
                  </Button>
                </Td>
                <Td>
                  <Select
                    defaultValue={order.status}
                    style={{ width: 120 }}
                    onChange={(value) => handleStatusUpdate(order._id, value)}
                  >
                    //'Order Placed','Pending', 'Packed', 'Shipped',
                    'Delivered', 'Cancelled'
                    <Option value="Pending">Pending</Option>
                    <Option value="Packed">Packed</Option>
                    <Option value="Shipped">Shipped</Option>
                    <Option value="Delivered">Delivered</Option>
                    <Option value="Cancelled">Cancelled</Option>
                  </Select>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <Drawer
        title="Update Order Status"
        width={360}
        onClose={onClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="status"
            label="Order Status"
            rules={[
              { required: true, message: "Please select the order status" },
            ]}
          >
            <Select placeholder="Please select the order status">
              <Option value="Order Placed">Order Placed</Option>
              <Option value="Shipped">Shipped</Option>
              <Option value="Delivered">Delivered</Option>
              <Option value="Cancelled">Cancelled</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Status
            </Button>
          </Form.Item>
        </Form>
      </Drawer>

      <Modal
        title="Order Items"
        visible={modalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            Close
          </Button>,
        ]}
      >
        <List
          itemLayout="horizontal"
          dataSource={currentItems}
          renderItem={(item) => {
            if (!itemForms.has(item._id)) {
              // itemForms.set(item._id, Form.useForm()[0]);
            }

            console.log(item);

            return (
              <List.Item>
                <List.Item.Meta
                  title={item.product[0]?.title}
                  description={
                    <>
                      <div>Price: {item.price}</div>
                      <div>Description: {item.product[0]?.description}</div>
                      <div>
                        Tracking Status:
                        <Select
                          defaultValue={item.trackingStatus}
                          onChange={(value) =>
                            handleItemStatusUpdate(item._id, value)
                          }
                          style={{ width: 120, marginLeft: 8 }}
                        >
                          <Option value="Pending">Pending</Option>
                          <Option value="Packed">Packed</Option>
                          <Option value="Shipped">Shipped</Option>
                          <Option value="Delivered">Delivered</Option>
                          <Option value="Cancelled">Cancelled</Option>
                        </Select>
                      </div>
                    </>
                  }
                />
              </List.Item>
            );
          }}
        />
      </Modal>

      <Modal
        title="Customer Details"
        visible={customerModalVisible}
        onCancel={handleCustomerModalClose}
        footer={[
          <Button key="print" onClick={handlePrintPDF}>
            Print PDF
          </Button>,
          <Button key="close" onClick={handleCustomerModalClose}>
            Close
          </Button>,
        ]}
      >
        {selectedCustomer && (
          <div>
            <p>
              <strong>Full Name:</strong>{" "}
              {`${selectedCustomer.first_name} ${selectedCustomer.last_name}`}
            </p>
            <p>
              <strong>Email:</strong> {selectedCustomer.email}
            </p>
            <p>
              <strong>Phone:</strong> {selectedCustomer.phone_number}
            </p>
            <p>
              <strong>Address:</strong>
              {selectedCustomer.address
                ? `${selectedCustomer.address.address_name}, ${
                    selectedCustomer.address.house_number
                  }, ${selectedCustomer.address.street_name}, ${
                    selectedCustomer.address.locality_name || ""
                  }, ${selectedCustomer.address.town || ""}, ${
                    selectedCustomer.address.postcode
                  }`
                : "No address available"}
            </p>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default Order;
