import axios from "axios";

// export const serverName = "https://d-dot-server.onrender.com"
// export const serverName = "http://localhost:3007";
export const serverName = "https://devapi.ddotcart.com";
// export const serverName = "https://api.ddotcart.com";

// const baseURL =
//   process.env.NODE_ENV === "development"
//     ? process.env.REACT_APP_API_URL_DEV
//     : process.env.NODE_ENV === "production"
//     ? process.env.REACT_APP_API_URL_PROD
//     : process.env.REACT_APP_API_URL;
const baseURL = `${serverName}/api/v1`
export const imageURL = `${serverName}/images/`;
export const avatarImageURL = `${serverName}/images/avatars/`;
export const bannerImageURL = `${imageURL}banner-images/`;

let token = localStorage.getItem("token");

const instance = axios.create({
  baseURL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptors
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// HTTP GET method
export const get = (url, config = {}) => {
  return instance.get(url, config);
};

// HTTP POST method
export const post = (url, data = {}, config = {}) => {
  return instance.post(url, data, config);
};

// HTTP PUT method
export const put = (url, data = {}, config = {}) => {
  console.log(url, new Date().toLocaleString());

  return instance.put(url, data, config);
};

// HTTP DELETE method
export const remove = (url, config = {}) => {
  return instance.delete(url, config);
};

// HTTP PATCH method
export const patch = (url, data = {}, config = {}) => {
  return instance.patch(url, data, config);
};

export default instance;
