import React, { useState, useEffect } from "react";
import {
  Table,
  Th,
  Thead,
  Tbody,
  Td,
  Tr,
  Title,
  TopContainer,
} from "../Product/ProductElement";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  bannerImageURL,
  get,
  post,
  put,
  remove,
  serverName,
} from "../../helpers/apiConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  Button,
  Upload,
  Drawer,
  Form,
  Input,
  Switch,
  message,
  Modal,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  removeImageFromAzure,
  uploadImageToAzure,
} from "../../utils/uploadImageToAzure";

function Banner() {
  const [banners, setBanners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingBanner, setEditingBanner] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const token = localStorage.getItem("token");
  const [isUpLoading, setIsUpLoading] = useState(false);

  // const IMAGE_URL = process.env.REACT_APP_BLOB_IMAGE_BASE_URL || "";
  const IMAGE_URL = "https://ddotstaticdev.blob.core.windows.net/images/";

  const getAllBanners = async () => {
    try {
      setIsLoading(true);
      const response = await get(`/home/banner?token=${token}`);
      if (response.data.success) {
        setBanners(response.data.banners);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error("Error fetching banners:", e);
    }
  };

  useEffect(() => {
    getAllBanners();
  }, []);

  const handleEdit = (banner) => {
    setEditingBanner(banner);
    form.setFieldsValue(banner);
    setDrawerVisible(true);
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this banner?",
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => handleDelete(id),
    });
  };

  const handleDelete = async (id) => {
    try {
      const res = await remove(`/home/banner/${id}`);
      console.log(res);
      
      if (res.data.success) {
        setBanners(banners.filter((banner) => banner._id !== id));
      }
    } catch (error) {
      console.error("There was an error deleting the banner!", error);
    }
  };

  const handleAddBanner = () => {
    setEditingBanner(null);
    form.resetFields();
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
    form.resetFields();
  };

  const onFinish = async (values) => {
    setIsUpLoading(true);
    try {
      const formData = new FormData();

      for (const key in values) {
        if (key === "image" && values[key]) {
          console.log("log", IMAGE_URL + values.image);

          if (editingBanner && typeof values.image != "string") {
            await removeImageFromAzure(
              IMAGE_URL + editingBanner?.image,
              "images/banner-images"
            );
          }
          if (typeof values.image != "string") {
            const url = await uploadImageToAzure(
              values.image.fileList[0].originFileObj,
              "images/banner-images"
            );
            const blobName = url.split(`/images/`)[1];
            console.log("blobName, ", blobName);
            formData.append("image", blobName);
          }
        } else {
          formData.append(key, values[key]);
        }
      }

      let response;
      if (editingBanner) {
        response = await put(
          `/home/banner/${editingBanner._id}?token=${token}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        response = await post(`/home/banner?token=${token}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      if (response.data.success) {
        message.success(
          `Banner ${editingBanner ? "updated" : "added"} successfully`
        );
        onClose();
        getAllBanners();
      } else {
        message.error(`Failed to ${editingBanner ? "update" : "add"} banner`);
      }
      setIsUpLoading(false);
    } catch (error) {
      setIsUpLoading(false);
      console.error(
        `Error ${editingBanner ? "updating" : "adding"} banner:`,
        error
      );
      message.error(`Failed to ${editingBanner ? "update" : "add"} banner`);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = banners.filter(
      (banner) =>
        banner.title.toLowerCase().includes(query) ||
        banner.description.toLowerCase().includes(query)
    );
    setBanners(filtered);
  };

  const handleVisibilityChange = async (banner, visible) => {
    try {
      const response = await put(`/home/banner/${banner._id}?token=${token}`, {
        visible,
      });
      if (response.data.success) {
        message.success(
          `Banner visibility ${visible ? "enabled" : "disabled"}`
        );
        getAllBanners();
      } else {
        message.error(`Failed to change banner visibility`);
      }
    } catch (error) {
      console.error("Error updating banner visibility:", error);
      message.error("Failed to change banner visibility");
    }
  };

  return (
    <div style={{ backgroundColor: "#FFF", width: "100vw" }}>
      <TopContainer>
        <Title>Home Banners</Title>

        <Input
          placeholder="Search banners"
          value={searchQuery}
          onChange={handleSearch}
          style={{ marginRight: 10, width: 200 }}
        />

        <Button type="default" onClick={handleAddBanner} style={{ margin: 10 }}>
          Add Banner
        </Button>
      </TopContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Id</Th>
            <Th>Title</Th>
            <Th>Description</Th>
            <Th>Images</Th>
            <Th>Visible</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading && <LoadingSpinner />}
          {banners.map((banner) => (
            <Tr key={banner._id}>
              <Td>{banner._id}</Td>
              <Td>{banner.title}</Td>
              <Td>{banner.description}</Td>
              <Td>
                <img
                  src={IMAGE_URL + banner?.image}
                  alt={banner.title}
                  width="50"
                  style={{ marginRight: 5 }}
                />
              </Td>
              <Td>
                <Switch
                  checked={banner.visible}
                  onChange={(checked) =>
                    handleVisibilityChange(banner, checked)
                  }
                />
              </Td>
              <Td>
                <EditOutlined
                  onClick={() => handleEdit(banner)}
                  style={{ marginRight: 8, cursor: "pointer" }}
                />
                <DeleteOutlined
                  onClick={() => confirmDelete(banner._id)}
                  style={{ cursor: "pointer" }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Drawer
        title={editingBanner ? "Edit Banner" : "Add Banner"}
        width={720}
        onClose={onClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            name="title"
            label="Title"
            rules={[
              { required: true, message: "Please enter the banner title" },
            ]}
          >
            <Input placeholder="Please enter the banner title" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: "Please enter the banner description",
              },
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Please enter the banner description"
            />
          </Form.Item>

          <Form.Item
            name="image"
            label="Image"
            rules={[
              {
                required: false,
                message: "Please select an image for the category",
              },
            ]}
            valuePropName="file"
            getValueFromEvent={(e) => (e.file ? e : null)}
          >
            <Upload
              name="image"
              maxCount={1}
              listType="picture"
              beforeUpload={() => false} // Prevent automatic upload
            >
              <Button  icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button disabled={isUpLoading} type="primary" htmlType="submit">
              {isUpLoading
                ? "Please wait..."
                : editingBanner
                ? "Update Banner"
                : "Add Banner"}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default Banner;
