import React, { useEffect, useState } from "react";
import axios from "axios";
import parse from 'html-react-parser';
import { get } from "../../helpers/apiConfig";

const PrivacyPolicy = () => {
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    try {
      setIsLoading(true);
      const response = await get("/settings/privacy-policy");

      if (response?.data?.success) {
        setData(response.data.data);
      }

      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      style={{
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        color: "#000",
      }}
    >
      
      <div style={{ marginTop: "20px" }}>
        {data ? (
          <div>{parse(data)}</div>
        ) : isLoading ? (
          <p>Loading...</p>
        ) : (
          <p>No content available</p>
        )}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
