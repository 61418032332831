import styled from 'styled-components'

export const Main = styled.div`
    // display:grid;
    justify-content: center;
    z-index: 1;
    margin: auto;
    width: 35%;
    align-items: center;
    padding: 50px 0px 50px 0px;

    @media screen and (max-width: 500px) {
        width: 70%;
    }
    @media screen and (max-width: 400px) {
        width: 68%;
    }  
        
`;

export const Text = styled.div`
    text-align: center;
    color: #000;
    font-family: "Poppins", sans-serif;   
    font-weight: bold;
    font-size: 24px;
    padding: 10px;
`
export const SubText = styled.div`
    color: #949CA9;
    font-family: "Poppins", sans-serif;  
    font-size: 14px; 
    text-align: center;
`


export const Label = styled.label`
    display: block;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #000;
    margin-bottom: 5px;
    text-align: left; // Align text to the left if needed
`;

export const InputDiv = styled.div`
    margin-top: 20px;
`;

export const Input = styled.input`
    border: 1px solid #949CA9;
    font-family: "Poppins", sans-serif;   
    padding: 10px;
    border-radius: 5px;
    color: #949CA9;
    width: 95%;
    font-size: 14px; 

    &::placeholder {
        color: #949CA9; // Adjust placeholder color if needed
        font-size: 14px; // Make sure this matches the font size of the input
        opacity: 1; // Ensure placeholder text is fully opaque
    }
`;




export const AccDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
export const AccText = styled.div`
    color: #000;
    font-family: "Poppins", sans-serif;   
    padding-inline: 5px;
`
export const Signup = styled.button`
    color: #2F93F6;
    font-family: "Poppins", sans-serif;   
    cursor: pointer; 
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 15px;
`;

export const Column = styled.div`
  flex: 1;
  min-width: 200px; // Adjust as necessary
`;

