

import React, { useState, useEffect } from "react";
import {
  Table,
  Th,
  Thead,
  Tbody,
  Td,
  Tr,
  Title,
  TopContainer,
} from "../Product/ProductElement";
import { get, post } from "../../helpers/apiConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import { notification, Button, Modal, List, Input } from "antd";

function PaymentSummary() {
  const [summary, setSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totals, setTotals] = useState({});
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendorDetails, setVendorDetails] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPayoutModalVisible, setIsPayoutModalVisible] = useState(false);
  const [payNowAmount, setPayNowAmount] = useState("");
  const [payoutDetails, setPayoutDetails] = useState(null);
  let token = localStorage.getItem("token");

  useEffect(() => {
    fetchPaymentSummary();
  }, []);

  const fetchPaymentSummary = async () => {
    try {
      setIsLoading(true);
      const response = await get(`/orders/payment-summary`);
      if (response.data.success) {
        setSummary(response.data.summary);
        setTotals({
          totalForAdmin: response?.data.totalForAdmin.toFixed(2),
          totalAdminFee: response?.data.totalAdminFee.toFixed(2),
          totalNetAmount: response?.data.totalNetAmount.toFixed(2),
          totalOrders: response?.data.totalOrders.toFixed(2),
          totalConfirmed: response?.data.totalConfirmed.toFixed(2),
          totalPaid: response?.data.totalPaid.toFixed(2),
          totalBalance: response?.data.totalBalance.toFixed(2),
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: "Error",
        description: "There was an error fetching the payment summary.",
        placement: "topRight",
      });
    }
  };

  const handlePayoutClick = (vendor) => {
    setPayoutDetails({
      vendorId: vendor.vendorId,
      vendorName: vendor.vendorName,
      paidAmount: vendor.paidAmount,
      balanceAmount: vendor.balanceAmount,
    });
    setPayNowAmount("");
    setIsPayoutModalVisible(true);
  };

  const handlePayoutSubmit = async () => {
    console.log(payoutDetails);
    if (payoutDetails?.balanceAmount > 0) {
      try {
        const response = await post(`/orders/payout`, {
          vendorId: payoutDetails.vendorId,
          payNowAmount: parseFloat(payNowAmount),
        });
        if (response.data.success) {
          notification.success({
            message: "Success",
            description: "Payout processed successfully!",
          });
          setIsPayoutModalVisible(false);
          fetchPaymentSummary();
        } else {
          notification.error({
            message: "Error",
            description: "Failed to process payout.",
          });
        }
      } catch (error) {
        notification.error({
          message: "Error",
          description: "An error occurred while processing payout.",
        });
      }
    } else {
      notification.error({
        message: "Error",
        description: "No balance to payout.",
      });
    }
  };

  return (
    <div style={{ backgroundColor: "#FFF", width: "100vw" }}>
      <TopContainer>
        <Title>Order Summary</Title>
      </TopContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Vendor ID</Th>
            <Th>Vendor Name</Th>
            <Th>Total Orders</Th>
            <Th>Total Payment</Th>
            <Th>Confirmed Amount</Th>
            <Th>Admin Commission</Th>
            <Th>Final Amount</Th>
            <Th>Paid Amount</Th>
            <Th>Balance Amount</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading && <LoadingSpinner />}
          {summary.map((vendor) => (
            <Tr key={vendor.vendorId}>
              <Td>{vendor.vendorId}</Td>
              <Td>{vendor.vendorName}</Td>
              <Td>{vendor.totalOrders}</Td>
              <Td>{vendor.totalPayment.toFixed(2)}</Td>
              <Td>{vendor.confirmedAmount.toFixed(2)}</Td>
              <Td>{vendor.adminFee.toFixed(2)}</Td>
              <Td>{vendor.finalAmount.toFixed(2)}</Td>
              <Td>{vendor.paidAmount.toFixed(2)}</Td>
              <Td>{vendor.balanceAmount.toFixed(2)}</Td>
              <Td>
                <Button onClick={() => handlePayoutClick(vendor)}>
                  Payout
                </Button>
              </Td>
            </Tr>
          ))}
          <Tr>
            <Td colSpan="2">Total for Admin</Td>
            <Td>{totals?.totalOrders}</Td>
            <Td>{totals?.totalForAdmin}</Td>
            <Td>{totals?.totalConfirmed}</Td>
            <Td>{totals?.totalAdminFee}</Td>
            <Td>{totals?.totalNetAmount}</Td>
            <Td>{totals?.totalPaid}</Td>
            <Td>{totals?.totalBalance}</Td>
            <Td></Td>
          </Tr>
        </Tbody>
      </Table>

      <Modal
        title="Process Payout"
        visible={isPayoutModalVisible}
        onCancel={() => setIsPayoutModalVisible(false)}
        onOk={handlePayoutSubmit}
      >
        <p>Vendor Name: {payoutDetails?.vendorName}</p>
        <p>Paid Amount: {payoutDetails?.paidAmount.toFixed(2)}</p>
        <p>Pending Balance: {payoutDetails?.balanceAmount.toFixed(2)}</p>
        <Input
          placeholder="Enter Pay Now Amount"
          value={payNowAmount}
          onChange={(e) => setPayNowAmount(e.target.value)}
          type="number"
        />
      </Modal>
    </div>
  );
}

export default PaymentSummary;
