import React from "react";
import { Main, BtnText } from "./ButtonElements";

const Button = ({ title, onClick, isLoading=false }) => {
  return (
    <Main onClick={onClick}>
      <BtnText onClick={onClick}>{isLoading ? "Please Wait..." :title}</BtnText>
    </Main>
  );
};

export default Button;
