import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Function to get last 12 months with year
const getLast12Months = () => {
  const labels = [];
  const date = new Date();

  for (let i = 0; i < 12; i++) {
    labels.unshift(
      date.toLocaleString("default", { month: "short", year: "numeric" })
    );
    date.setMonth(date.getMonth() - 1);
  }

  return labels;
};

const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

const LineGraph = ({ months, data=[] }) => {
  const lineData = {
    labels: getLast12Months(),
    datasets: [
      {
        label: "Monthly Data",
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        data: data ?? Array(10).fill(0),
      },
    ],
  };
  return (
    <div>
      <Line data={lineData} options={options} />
    </div>
  );
};

export default LineGraph;
